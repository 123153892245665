import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const CareersPage = () => {
  return (
    <Layout pageTitle="Careers">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../media/careers_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                objectPosition="bottom"
                layout="fullWidth" />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>COMPANY</h2>
                <h1>Careers</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl">
          <Row>
            <Col>
              <iframe
                id="careers-iframe"
                className="iframe-container"
                title="Wessuc's Open Positions"
                src="https://careers.wessuc.com/recruit/Portal.na?digest=DwOxWm@9hWiyOLi6ov4lFjGMKegWdKMrqr2UxYu1MCE-" 
                allowfullscreen="allowfullscreen"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default CareersPage